import { z } from "zod";
import {
  localeSchema,
  booleanComparisonExpSchema,
  orderBySchema,
  passwordSchema,
  stringComparisonExpSchema,
  timestamptzComparisonExpSchema,
  stringNumbersSchema,
  stringNumbersSchemaRefinement,
  passwordSchemaRefinement,
  phoneCCSchema,
  phoneCCSchemaRefinement,
  adminRoleSchema,
  uuidComparisonExpSchema,
} from ".";

export const usersSetInputSchema = z
  .object({
    locale: localeSchema.optional(),
    fullName: z.string().min(2).max(50),
    phone: stringNumbersSchema
      .min(7)
      .max(15)
      .superRefine(stringNumbersSchemaRefinement),
    phoneCC: phoneCCSchema.superRefine(phoneCCSchemaRefinement),
    countryId: z.string(),
  })
  .partial();

export type UsersSetInputSchema = z.infer<typeof usersSetInputSchema>;

export const usersBoolExpSchema = z.object({
  isAdmin: booleanComparisonExpSchema.nullish(),
  emailVerified: booleanComparisonExpSchema.nullish(),
  isBanned: booleanComparisonExpSchema.nullish(),
  createdAt: timestamptzComparisonExpSchema.nullish(),
  email: stringComparisonExpSchema.nullish(),
  fullName: stringComparisonExpSchema.nullish(),
  phone: stringComparisonExpSchema.nullish(),
  orgIds: uuidComparisonExpSchema.nullish(),
});

export type UsersBoolExpSchema = z.infer<typeof usersBoolExpSchema>;

export const usersOrderBySchema = z.object({
  createdAt: orderBySchema.nullish(),
});

export type UsersOrderBySchema = z.infer<typeof usersOrderBySchema>;

export const accountEditProfileArgsSchema = z.object({
  fullName: z.string().min(2).max(50),
  phone: stringNumbersSchema
    .min(7)
    .max(15)
    .superRefine(stringNumbersSchemaRefinement),
  phoneCC: phoneCCSchema.superRefine(phoneCCSchemaRefinement),
});

export type AccountEditProfileArgsSchema = z.infer<
  typeof accountEditProfileArgsSchema
>;

export const accountChangePasswordArgsSchema = z.object({
  newPassword: passwordSchema.superRefine(passwordSchemaRefinement),
  oldPassword: passwordSchema.superRefine(passwordSchemaRefinement),
});

export type AccountChangePasswordArgsSchema = z.infer<
  typeof accountChangePasswordArgsSchema
>;

export const accountChangeEmailRequestArgsSchema = z.object({
  newEmail: z.string().email(),
  password: passwordSchema.superRefine(passwordSchemaRefinement),
});

export type AccountChangeEmailRequestArgsSchema = z.infer<
  typeof accountChangeEmailRequestArgsSchema
>;

export const accountChangeEmailVerifyArgsSchema = z.object({
  newEmail: z.string().email(),
  otpCode: stringNumbersSchema
    .length(6)
    .superRefine(stringNumbersSchemaRefinement),
});

export type AccountChangeEmailVerifyArgsSchema = z.infer<
  typeof accountChangeEmailVerifyArgsSchema
>;

export const adminCreateInputSchema = z.object({
  email: z.string().email().endsWith("@mezan.sa"),
  password: passwordSchema.superRefine(passwordSchemaRefinement),
  locale: localeSchema,
  adminRoles: adminRoleSchema.array().min(1),
});

export type AdminCreateInputSchema = z.infer<typeof adminCreateInputSchema>;
